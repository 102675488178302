<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        no-hide-filters
        :filters="filters"
        sortBy="data"
        :sortDesc="true"
        @click="onClick"
        search
        ref="datatable"
      >
        <template v-slot:data="{ col }">
          {{ col | formatDateTime(getTimeZone) }}
        </template>
        <template v-slot:evento="{ col }">
          {{ eventosLabel[col] || col }}
        </template>
        <template v-slot:variaveis.nome_cliente="{ col, row }">
          <div>{{ row.nome_cliente }}</div>
          <small>{{ row.cpf_cliente ? row.cpf_cliente : '' }}</small>
        </template>
        <template v-slot:ferramenta="{ col, row }">
          <div>{{ canalLabel[row.canal] || row.canal }}</div>
          <small>{{ ferrmanetaLabel[col] || col }}</small>
        </template>
        <template v-slot:sucesso="{ col }">
          <Chip v-if="col" color="green" text="Enviado" small />
          <Chip v-else color="cyan" text="Falhou" small />
        </template>
        <template v-slot:logid="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <SidePopup
        title="Detalhes do envio de mensagens"
        v-on:close="onCloseDetails"
        overlayNotBack
        v-if="viewDetails._open"
      >
        <v-row>
          <v-col sm="4">
            <div class="info-show md">
              <div class="info-label">Id de envio</div>
              <div class="info-value">{{ viewDetails.logid }}</div>
            </div>
          </v-col>
          <v-col sm="4">
            <div class="info-show md">
              <div class="info-label">Cliente</div>
              <div class="info-value">{{ viewDetails.nome_cliente }}</div>
              <div class="info-value small">{{ viewDetails.cpf_cliente }}</div>
            </div>
          </v-col>
          <template
            v-if="
              viewDetails.ferramenta == 'ELASTIC_MAIL' ||
                viewDetails.ferramenta == 'SMTP'
            "
          >
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">Destinatário</div>
                <div class="info-value">{{ viewDetails.config.to }}</div>
              </div>
            </v-col>
          </template>
          <template v-if="viewDetails.ferramenta == 'zenvia'">
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">Destinatário</div>
                <div class="info-value">{{ viewDetails.config.telefone }}</div>
              </div>
            </v-col>
          </template>
          <v-col sm="4">
            <div class="info-show md">
              <div class="info-label">Data de envio</div>
              <div class="info-value">
                {{ viewDetails.data | formatDateTime(getTimeZone) }}
              </div>
            </div>
          </v-col>
          <v-col sm="4">
            <div class="info-show md">
              <div class="info-label">Situação</div>
              <div class="info-value">
                <Chip
                  v-if="viewDetails.sucesso"
                  color="green"
                  text="Enviado"
                  small
                />
                <Chip v-else color="cyan" text="Falhou" small />
              </div>
            </div>
          </v-col>
          <v-col sm="4">
            <div class="info-show md">
              <div class="info-label">Evento</div>
              <div class="info-value">
                {{ eventosLabel[viewDetails.evento] || viewDetails.evento }}
              </div>
            </div>
          </v-col>
          <v-col sm="4">
            <div class="info-show md">
              <div class="info-label">Forma de envio</div>
              <div class="info-value">
                {{ canalLabel[viewDetails.canal] || viewDetails.canal }}
              </div>
              <small>
                {{
                  ferrmanetaLabel[viewDetails.ferramenta] ||
                    viewDetails.ferramenta
                }}
              </small>
            </div>
          </v-col>
          <v-col sm="12">
            <LineDivider position="bottom">
              <h4>Configurações</h4>
            </LineDivider>
          </v-col>
          <template v-if="viewDetails.ferramenta == 'ELASTIC_MAIL'">
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">template de e-mail</div>
                <div class="info-value">
                  {{ viewDetails.config.template }}
                </div>
              </div>
            </v-col>
          </template>
          <template v-if="viewDetails.ferramenta == 'SMTP'">
            <v-col sm="5">
              <div class="info-show md">
                <div class="info-label">Host</div>
                <div class="info-value">
                  {{ viewDetails.config.host }}
                </div>
              </div>
            </v-col>
            <v-col sm="3">
              <div class="info-show md">
                <div class="info-label">Porta</div>
                <div class="info-value">
                  {{ viewDetails.config.port }}
                </div>
              </div>
            </v-col>
            <v-col sm="3">
              <div class="info-show md">
                <div class="info-label">Remetente</div>
                <div class="info-value">
                  {{ viewDetails.config.from }}
                </div>
              </div>
            </v-col>
          </template>
          <template v-if="viewDetails.ferramenta == 'zenvia'">
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">Mensagem</div>
                <div class="info-value">
                  {{ viewDetails.config.message }}
                </div>
              </div>
            </v-col>
          </template>
          <template v-if="viewDetails.ferramenta == 'onesignal'">
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">Mensagem</div>
                <div class="info-value">
                  {{ viewDetails.config.message }}
                </div>
              </div>
            </v-col>
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">Titulo</div>
                <div class="info-value">
                  {{ viewDetails.config.title }}
                </div>
              </div>
            </v-col>
            <v-col sm="4">
              <div class="info-show md">
                <div class="info-label">Link</div>
                <div class="info-value">
                  {{ viewDetails.config.link }}
                </div>
              </div>
            </v-col>
          </template>
          <v-col sm="12">
            <LineDivider position="bottom">
              <h4>Variáveis</h4>
            </LineDivider>
          </v-col>
          <v-col sm="12">
            <DataTable
              hide-footer
              :headers="[
                {
                  text: 'Nome',
                  value: 'key',
                  sortable: false,
                  clickable: false
                },
                {
                  text: 'Descritor',
                  value: 'descritor',
                  sortable: false,
                  clickable: false
                },
                {
                  text: 'Valor',
                  value: 'value',
                  sortable: false,
                  clickable: false
                }
              ]"
              :data="viewDetails.variaveis_list"
            >
              <template v-slot:descritor="{ col }">
                <span class="variable-descriptor">{{ col }}</span>
              </template>
            </DataTable>
          </v-col>
          <v-col sm="12">
            <LineDivider position="bottom">
              <h4>Resposta do serviço</h4>
            </LineDivider>
          </v-col>
          <v-col sm="12">
            <p>{{ viewDetails.resposta.dump }}</p>
          </v-col>
        </v-row>
      </SidePopup>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { mapGetters } from 'vuex'
import Api from '@/service/api-web'
import Chip from '@/components/Chip'
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import * as _ from 'lodash'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'LogEnvioMensagemTable',
  components: {
    LineDivider,
    DataTable,
    Panel,
    FormButtonMenu,
    Chip,
    SidePopup,
    MarketingModules
  },
  data: () => ({
    headers: [
      {
        text: 'Data de envio',
        value: 'data'
      },
      {
        text: 'Cliente',
        value: 'variaveis.nome_cliente',
        type: 'string'
      },
      {
        text: 'Evento',
        value: 'evento',
        type: 'string'
      },
      {
        text: 'Ferramenta',
        value: 'ferramenta',
        type: 'string'
      },
      {
        text: 'Situação',
        value: 'sucesso'
      },
      {
        text: 'Opções',
        value: 'logid',
        clickable: false,
        sortable: false
      }
    ],
    tableOptions: [{ text: 'Detalhes', value: 'detalhe' }],
    eventosLabel: {},
    ferrmanetaLabel: {
      SMTP: 'SMTP',
      ELASTIC_MAIL: 'Elasticmail',
      onesignal: 'One Signal',
      zenvia: 'Zenvia'
    },
    canalLabel: {
      email: 'E-mail',
      sms: 'Sms',
      push: 'Notificações push',
      whatsapp: 'Whatsapp'
    },
    viewDetails: {
      _open: false
    },
    eventosList: [],
    modulosForm: [
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getTimeZone', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/mensagem-comunicacao/log-envio/${this.getFornecedorId}`
    },
    filters() {
      return [
        { label: 'Data do envio inicio', query: 'dataInicial', type: 'date' },
        { label: 'Data do envio fim', query: 'dataFinal', type: 'date' },
        {
          label: 'Forma de envio',
          query: 'canal',
          options: [
            { text: 'Todos', value: '' },
            { text: 'E-mail', value: 'email' },
            { text: 'Sms', value: 'sms' },
            { text: 'Notificações push', value: 'push' },
            { text: 'Whatsapp', value: 'whatsapp' }
          ]
        },
        {
          label: 'Evento',
          query: 'evento',
          default: '',
          type: 'autocomplete',
          options: [
            {
              value: '',
              text: 'Todos'
            },
            ...this.eventosList
          ]
        },
        {
          label: 'Cliente',
          query: 'idcliente',
          type: 'autocomplete',
          request: {
            value: 'idcliente',
            text: 'nome',
            endpoint: `/api/v4/client/list/by-criteria/${this.getFornecedorId}`,
            query: 'search'
          }
        }
      ]
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  mounted() {
    this.loadEventosEmail()
    this.loadEventos('sms')
    this.loadEventos('push')
    this.loadEventos('whatsapp')
  },
  methods: {
    onClick(item) {
      const keysVariaveis = Object.keys(_.get(item, 'variaveis', {})) || []
      this.viewDetails = {
        _open: true,
        ...item,
        variaveis_list: keysVariaveis.map(key => ({
          key,
          value: _.get(item, ['variaveis', key], ''),
          descritor: `{{${key}}}`
        }))
      }
      return item
    },
    onCloseDetails() {
      this.viewDetails = {
        _open: false
      }
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'detalhe':
          return this.onClick(item)
      }
    },
    loadEventos(canal) {
      Api.v4.mensagensautomaticas.listaEventos(canal).then(resp => {
        const eventos = _.get(resp, 'data', [])
        eventos.forEach(evento => {
          this.eventosList.push({
            text: evento.evento,
            value: evento.alias_evento
          })
          this.eventosLabel = {
            ...this.eventosLabel,
            [evento.alias_evento]: evento.evento
          }
        })
      })
    },
    loadEventosEmail() {
      Api.v4.mensagensautomaticas.listaEventos('email').then(resp => {
        const provedores = ['COMMERCE', 'ELASTIC_MAIL', 'SMTP']
        const eventos = _.get(resp, 'data', [])
        eventos.reduce((_eventos, evento) => {
          if (evento.provedores.some(p => provedores.includes(p.provedor))) {
            this.eventosList.push({
              text: evento.evento,
              value: evento.alias_evento
            })
            this.eventosLabel = {
              ...this.eventosLabel,
              [evento.alias_evento]: evento.evento
            }
          }
          return _eventos
        }, [])
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.variable-descriptor {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
</style>
